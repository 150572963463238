// Note this file needs to be imported _first_ in the pack so that the
// env is fully setup _before_ our graphql library is loaded by the
// response components. As they pickup their env 'on import' rather
// than 'when called'.

import '../foreman_env.js.erb'

PollEv.env.set('proxy_enabled', false)
// Using `location.origin` here as this is used for both the main
// participant site, and the embed site, which are on different domains
PollEv.env.set('api_url', location.origin)
PollEv.env.set('graphql_url', location.origin + '/graphql')
